import React, { useState } from 'react';
import Slider from "react-slick";
import VisibilitySensor from 'react-visibility-sensor';
import { Link, graphql, useStaticQuery } from 'gatsby';

import Footer from './../components/footer';
import Navbar from './../components/navbar';
import Seo from './../components/seo';
import BasicText from '../components/content/basicText';
import FadeIn from '../components/fade';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';

import './../styles/layout.scss';
import './../styles/home.scss';
import './../styles/slick/slick-theme.scss';
import './../styles/slick/slick.scss';

const Hero = ({data}) => {
  const [heroAnimate, setHeroAnimate] = useState(false);
  
  return <>
    <div class='hero-container'>
      <VisibilitySensor onChange={isVisible => { if (isVisible) setHeroAnimate(true) }}>
        <FadeIn triggered={heroAnimate}>
          <div class='hero-text'>
              <h1 class="hero-title">{data.title}</h1>
              <h3 class="hero-sub">{data.subtitle}</h3>
              <Link to={'/services'}>
                <button type='button' class="btn btn-outline-primary">Learn more</button>
              </Link>
          </div> 

          <div class='hero-img-wrap'>
            <div class="hero-aspect-ratio">
              <div class="hero-img-overlay hero-2" />
              <div class="hero-img-overlay hero-1" />
              <div class="hero-img" style={{backgroundImage: `url(${data.image})`}}>
            </div>
            </div>
          </div>
        </FadeIn>
      </VisibilitySensor>
      {/* <div class='center-title'><button></button></div> */}
    </div>
  </>;
}

const TitlePair = (props) => {
  return <>
    <div class='center-title'>
      <h3>{props.subtitle.toUpperCase()}</h3>
      <h1>{props.title}</h1>
    </div>
  </>
}

const SideBySide = ({imageSide, imageURL, children, ...props}) => {
  return <>
    <div class={`side-container image-${imageSide}`} imageSide='imageSide'>
      <div class="image-side">
        <img class='side-container-image' src={imageURL}></img>
      </div>
      <div class='text-side'>{children}</div>
    </div>
  </>
}


const IndexPage = ({data}) => {

  const settings = {
    slidesToShow: 2,
    dots: true,
    infinite: true,
    speed: 500,
    autoplay:true,

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          dots: true,
          infinite: true,
          speed: 500,
          autoplay:true,
        }
      }
    ]
  };

  const [aboutAnimate, setAboutAnimate] = useState(false);
  const [servicesAnimate, setServicesAnimate] = useState(false);
  const [testimonialsAnimate, setTestimonialsAnimate] = useState(false);
  const [startedAnimate, setStartedAnimate] = useState(false);

  return <>
    <Seo title="Home" />

    <Navbar />

    <Hero data={{
      title: data.strapiHomepage.Title,
      subtitle: data.strapiHomepage.Subtitle,
      image: data.strapiHomepage.HeroImage.localFile.publicURL
    }} />

    {/* About */}
    <section id="about">
      <div class="home-resp-width-wrapper">
          <div class='about-box'>
            <FadeIn triggered={aboutAnimate}>
              <VisibilitySensor onChange={isVisible => { if (isVisible) setAboutAnimate(true) }}>
                <TitlePair
                  subtitle='WHO WE ARE'
                  title='About Us'
                />
              </VisibilitySensor>
              <BasicText data={{text: data.strapiHomepage.About}}></BasicText>
            </FadeIn>
          </div>
      </div>
    </section>

    <section id="services">
        <div class="home-resp-width-wrapper">

        <VisibilitySensor onChange={isVisible => { if (isVisible) setServicesAnimate(true) }}>
          <FadeIn triggered={servicesAnimate}>

            <TitlePair
              title={data.strapiHomepage.ServicesSectionTitle}
              subtitle={data.strapiHomepage.ServicesSectionSubtitle}
            />

            {data.strapiHomepage.ServicesStep.map((item, index) => 
              <SideBySide
                imageSide={index % 2 === 0 ? 'right' : 'left'}
                imageURL={item.ServiceImage.localFile.publicURL}
              >
                <h2>{item.ServiceTitle}</h2>
                <BasicText data={{text: item.ServiceDescription}}></BasicText>
              </SideBySide>
            )}
          
          </FadeIn>
        </VisibilitySensor>

        </div>
      </section>

      {/* Testimonials */}
      
      <section id="testimonials">
        <div class="home-resp-width-wrapper">
          <VisibilitySensor onChange={isVisible => { if (isVisible) setTestimonialsAnimate(true) }}>
            <FadeIn triggered={testimonialsAnimate}>
              <TitlePair
                title={data.strapiHomepage.TestimonialsSectionTitle}
                subtitle={data.strapiHomepage.TestimonialsSectionSubtitle}
              />
              <div class="slider-wrapper">
                <Slider {...settings}>
                  {data.strapiHomepage.Testimonial.map((item, index) => <>
                      <div class='slider-item'>

                        <p><FontAwesomeIcon icon={faQuoteLeft}/> &nbsp; {item.Quote}</p>
                        <p class="home-quote-attribution">{item.Attribution}</p>
                      </div>
                    </>
                  )}
                </Slider>
              </div>
            </FadeIn>
          </VisibilitySensor>
        </div>  
      </section>

      <section id="find-out-more">
        <div class="home-resp-width-wrapper">
          <VisibilitySensor onChange={isVisible => { if (isVisible) setStartedAnimate(true) }}>
            <FadeIn triggered={startedAnimate}>
              <TitlePair
                subtitle='GET STARTED'
                title='Ready to find out more?'
              />

              <Link to="/services" class="btn btn-primary">
                {'Services >'}
              </Link>
            </FadeIn>
          </VisibilitySensor>
        </div>
      </section>
    
    
    <Footer />
  </>;
}

export default IndexPage;

export const pageQuery = graphql`
  query IndexQuery {
    strapiHomepage {
      Subtitle
      Title

      HeroImage {
        localFile {
          publicURL
        }
      }

      About

      ServicesSectionTitle
      ServicesSectionSubtitle
      
      ServicesStep {
        ServiceDescription
        ServiceTitle
        ServiceImage {
        localFile {
          publicURL
          }
        }
      }

      TestimonialsSectionTitle
      TestimonialsSectionSubtitle

      Testimonial {
        Attribution
        Quote
      }
    }
  }
  
`;
